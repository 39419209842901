import React, { useState, Suspense } from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import Spinner from '@components/Spinner';
import ScrollToTop from '@components/ScrollTop';

const MainPage = React.lazy(() => import('@pages/MainPage'));
const Login = React.lazy(() => import('@pages/Login'));
const Page404 = React.lazy(() => import('@pages/Page404'));

function App() {
  
  const [loading, setLoading] = useState(false);

  return (
    <BrowserRouter>
      <ScrollToTop />
      <Suspense fallback={<Spinner />}>
        <div className='min-height-100vh bg-light position-relative'>
          {loading && <Spinner />}
          <Routes>
            <Route path="*" element={<MainPage setLoading={setLoading} />} />
            <Route path="/login" element={<Login setLoading={setLoading} />} />
            <Route path="/404" element={<Page404 />} />
          </Routes>
          <div id="notifications" className="notification-container"></div>
        </div>
      </Suspense>
    </BrowserRouter>
  );
}

export default App;
