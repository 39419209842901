import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import it from '@assets/languages/it.json';

const resources = {
  it: { translation: it },
};

i18n
  .use(initReactI18next)
  .init({
    resources,
    lng: 'it', // lingua di default
    fallbackLng: 'it', // lingua di fallback
    interpolation: {
      escapeValue: false,
    },
});