import { legacy_createStore as createStore } from 'redux'

const initialState = {
  sidebarShow: true,
  theme: 'light',
}

const changeState = (state: any = initialState, action: any): any => {
  const { type, ...rest } = action;
  switch (type) {
    case 'set':
      return { ...state, ...rest };
    default:
      return state;
  }
};


const store = createStore(changeState)
export default store
